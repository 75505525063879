<template>
  <!-- 签到培训 -->
  <div class="signinTraining">
    <van-nav-bar :title="$t('signinTraining.StTxt1')"
                 left-text
                 left-arrow
                 @click-left="onClickLeft"
                 @click-right="onClickRight"
                 :fixed="true" />
    <div class="fixedH"
         style="height: 1.22667rem;"></div>
    <div class="overflow_div">
      <my-calendar @outDate="getdate"
                   :yymmddArr="yymmdd" />
      <div class="link_others">
        <van-list v-model="loading"
                  :finished="finished"
                  :finished-text="$t('signinTraining.StTxt2')">
          <van-cell v-for="(item, index) in viewList"
                    :key="index"
                    class="examList"
                    @click="toDetais(item,index)">
            <img v-if="item.csurl != ''"
                 v-lazy="item.csurl"
                 alt />
            <img v-else
                 src="@/assets/img/default_bgimg.jpg"
                 alt />
            <div class="info">
              <!-- <p class="examName">招聘类-文秘招聘考试</p> -->
              <div class="footer">
                <span class="courseName">{{ item.coursename }}</span>
                <div class="right">
                  <span class="score">{{ item.coursecredit }}分</span>
                </div>
              </div>
              <span class="lecturerName">{{ item.coursetype }}</span>
              <div class="footer">
                <span class="date"></span>
                <div class="foot_right">
                  <van-button plain
                              type="info"
                              @click.stop="showQRCode(item,index)">{{$t('signinTraining.StTxt3')}}</van-button>
                </div>
              </div>
            </div>
          </van-cell>
        </van-list>
      </div>
    </div>
    <van-dialog v-model="show"
                title
                :showConfirmButton="false">
      <van-icon name="cross"
                @click="closeEwm" />
      <div class="title">{{codeCoursename}}</div>
      <div class="ewm">
        <qrcode :text="url+v" />
      </div>
      <p>
        {{$t('signinTraining.StTxt4')}}
        <br />{{$t('signinTraining.StTxt5')}}
      </p>
    </van-dialog>
  </div>
</template>

<script>
import { getSignDateList, getSignCourseList } from '@api/wxpx.js'
const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {}
import myCalendar from './myCalendar'
import qrcode from '@components/qrcode'
import { encrypt } from '@/utils/aes.js'
export default {
  components: {
    myCalendar,
    qrcode
  },
  data () {
    return {
      show: false,
      userInfo,
      yymmdd: [],
      viewList: [],
      url: '',
      yymm: '',
      nowselectData: '',
      setData: '',
      isLoading: false,
      loading: true,
      finished: true,
      show: false,
      sign: false,
      codeCoursename: '',
      v: '', // 随机字符
      t: null, // 定时器
      k: '' //加密字段
    }
  },
  created () {
    // this.getSign();
  },
  methods: {
    onClickLeft () {
      this.$router.push('/home')
    },
    onClickRight () { },
    async getdate (d) {
      this.nowselectData = d
      this.yymm = d.replace(/\//g, '').slice(0, 6)
      let res1 = await getSignDateList({
        empid: this.userInfo.empid,
        yymm: this.yymm
      })
      this.yymmdd = res1.data
      let res2 = await getSignCourseList({
        empid: this.userInfo.empid,
        yymmdd: d
      })
      if (res2.data.length > 0)
      {
        this.viewList = res2.data
      } else
      {
        this.viewList = []
      }
    },
    // 签到详情
    toDetais (ite, idx) {
      console.log(ite, 'ite')
      this.$router.push({
        path: '/registrationDetails',
        query: {
          mautoid: ite.autoid
        }
      })
      window.sessionStorage.setItem('v8_pxqd_data', JSON.stringify(ite))
      window.sessionStorage.setItem('recordSigninNowData', this.nowselectData)
    },
    // 扫码
    showQRCode (ite, idx) {
      console.log(ite)
      this.codeCoursename = ite.coursename
      this.url = window.V3IsClound
        ? window.V3url +
        '/weixin/queryrpt/zhongzhuan.html?hyrptparas=|V8H5|scanSignIn|mautoid_' +
        ite.autoid +
        '@name_' +
        encodeURI(ite.coursename) +
        '@v_v8pcnotimestamp' +
        '|'
        : window.V3url
          ? window.V3url +
          '/weixinlocal/queryrpt/zhongzhuan.html?hyrptparas=|V8H5|scanSignIn|mautoid_' +
          ite.autoid +
          '@name_' +
          encodeURI(ite.coursename) +
          '@v_v8pcnotimestamp' +
          '|'
          : location.origin +
          '/scanSignIn?mautoid=' +
          ite.autoid +
          '&name=' +
          encodeURI(ite.coursename)
      // console.log(this.url)
      // 增加时间戳加密字段
      this.v = '&v=' + encrypt(this.k, Date.parse(new Date()).toString())
      console.log(this.v, Date.parse(new Date()).toString())
      this.show = true
      // 每10分钟刷新
      this.t = setInterval(() => {
        this.v = '&v=' + encrypt(this.k, Date.parse(new Date()).toString())
        console.log(this.v, Date.parse(new Date()).toString())
      }, 1000 * 10)
    },
    closeEwm () {
      clearInterval(this.t)
      this.show = false
    }
  }
}
</script>

<style lang="less" scoped>
.signinTraining {
  height: 100vh;
  background: #eceaea;
  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  .overflow_div {
    overflow: auto;
    height: calc(100% - 100px);
  }
  .test-page {
    .top {
      background: #2b8df0;
      background-size: 100% 100%;
      overflow: hidden;
      height: 200px;
      text-align: center;
      div {
        height: calc(100% - 40px);
        background: #fff;
        margin: 40px 28px 0 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 16px 16px 0 0;
        span {
          color: #2b8df0;
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: 500;
        }
        span:first-child {
          color: #333333;
        }
        span:last-child {
          color: #333333;
        }
      }
    }
    .content {
      margin: -2px 28px 0 28px;
      overflow: hidden;
      border-radius: 0 0 16px 16px;
      // box-shadow: rgba(225, 225, 225, 0.7) 0 10px 20px 0;
    }
    .month {
      background: #ffffff;
      margin: 0;
      padding: 10px 15px;
      display: flex;
      justify-content: space-between;
      li {
        text-transform: uppercase;
        letter-spacing: 0;
      }
      .arrow {
        color: #5283c4;
        font-size: 12px;
        i {
          font-size: 13px;
          top: 2px;
        }
      }
      .year-month {
        font-size: 17px;
      }
    }

    .weekdays {
      /*头部星期*/
      margin: 0;
      padding: 10px 0;
      background-color: #ffffff;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      li {
        display: inline-block;
        text-align: center;
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        // line-height: 24px;
        color: #333333;
      }
    }

    .days {
      /*日期*/
      padding: 0 0 10px;
      background: #ffffff;
      margin: -2px 0;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;

      li {
        list-style-type: none;
        width: 14.2%;
        padding: 1%;
        box-sizing: border-box;
        height: 80px;
        margin-bottom: 10px;
        text-align: center;
        font-size: 40px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        // line-height: 26px;
        color: #333333;
        .cli {
          position: relative;
          // width: 100%;
          // height: 80px;
          width: 68px;
          height: 68px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 10px;
        }
        .chicked {
          background: #2b8df0;
          border-radius: 50%;
          color: #fff;
        }
      }
    }
    .sigInBtn {
      background: #ffffff;
      text-align: center;
      .van-button {
        width: 80%;
        height: 80px;
        margin-bottom: 30px;
        border-radius: 8px;
        background-color: #2b8df0;
        border: 0.02667rem solid #2b8df0;
        .van-button__text {
          font-size: 36px;
          font-family: Source Han Sans CN;
          font-weight: 400;
        }
      }
    }
  }
  .link_others {
    margin-top: 30px;
    .examList {
      padding: 32px;
      .van-cell__value {
        display: flex;
        img {
          width: 310px;
          height: 190px;
          margin-right: 18px;
        }
        .info {
          flex: 1;
          .lecturerName {
            color: #999999;
            font-size: 28px;
            font-family: Source Han Sans CN;
            font-weight: 500;
          }
          .footer {
            display: flex;
            justify-content: space-between;
            // align-items: flex-end;
            align-items: baseline;
            .courseName {
              width: 70%;
              margin: 0;
              font-size: 32px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              // line-height: 54px;
              color: #333333;
              // opacity: 1;
            }
            .date {
              color: #999999;
              font-size: 24px;
            }
            .right {
              .score {
                font-size: 32px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                line-height: 27px;
                color: #666666;
              }
            }
            .foot_right {
              position: absolute;
              bottom: 0;
              right: 0;
              ::v-deep.van-button {
                padding: 0;
                width: 160px;
                height: 60px;
                border-radius: 8px;
                border: 1px solid #2b8df0;
                .van-button__text {
                  font-size: 32px;
                  font-family: Source Han Sans CN;
                  font-weight: 500;
                  line-height: 27px;
                  color: #2b8df0;
                }
              }
            }
          }
        }
      }
    }
  }
  .van-dialog {
    /deep/.van-dialog__content {
      position: relative;
      height: 100%;
      text-align: center;
      .title {
        padding-top: 0.5rem;
        font-size: 32px;
        font-family: Source Han Sans CN;
        font-weight: 500;
      }
      .ewm {
        width: 4.52rem;
        margin: 0 auto;
        padding-top: 1rem;
        min-height: 5.52rem;
        img {
          width: 4.52rem;
          height: 4.52rem;
        }
      }
      p {
        width: 100%;
        margin-top: 0.5rem;
      }
      .van-icon {
        width: 28px;
        height: 28px;
        position: absolute;
        right: 40px;
        top: 32px;
      }
    }
  }
}
</style>